<template>
  <form>
    <!--begin::Header-->
    <div
      class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
        <i class="flaticon2-shopping-cart-1 text-success" />
      </span>
      <h4 class="text-white m-0 flex-grow-1 mr-3">Wybrane nagrody</h4>
      <button type="button" class="btn btn-danger btn-sm" v-if="getCart.length>0" @click="clearCart">wyczyść</button>
    </div>
    <!--end::Header-->

    <div class="scroll scroll-push">
      <perfect-scrollbar
        class="scroll"
        style="max-height: 30vh; position: relative;"
      >
        <!--begin::Item-->
        <template v-for="(item, i) in getCart">
          <div
            class="d-flex align-items-center justify-content-between p-8"
            v-bind:key="i"
          >
            <div class="d-flex flex-column mr-2">
              <span
                class="font-weight-bold text-dark-75 font-size-lg text-hover-primary"
              >
                {{ item.prize.erpPrize.name }}
              </span>
              <span class="text-muted">
                {{ item.prize.erpPrize.code }}
              </span>
              <div class="d-flex align-items-center mt-2">
                <a
                  href="#"
                  class="btn btn-xs btn-light-success btn-icon mr-2"
                  @click="decAmount(item.prize)"
                >
                  <i class="ki ki-minus icon-xs"></i>
                </a>
                <span class="font-weight-bold mr-1 text-dark-75 font-size-3">
                  {{ item.quantity }}
                </span>
                <span class="text-muted mr-1">po</span>
                <span class="font-weight-bold mr-2 text-dark-75 font-size-3">
                  {{ item.prize.erpPrize.cost }}
                </span>
                <a
                  href="#"
                  class="btn btn-xs btn-light-success btn-icon"
                  @click="incAmount(item.prize)"
                >
                  <i class="ki ki-plus icon-xs"></i>
                </a>
                <span style="padding-left: 10px;">
                <a href="#" class="btn btn-sm btn-light-danger btn-icon"
                  @click="removePrize(item.prize)"
                >
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </a></span>
              </div>
            </div>
            <a href="#" class="symbol symbol-70 flex-shrink-0">
              <img :src="getFirstImageUrl(item.prize.images)" />
            </a>
          </div>
        </template>
        <!--end::Item-->
        <div class="separator separator-solid"></div>
      </perfect-scrollbar>
    </div>

    <!--begin::Summary-->
    <div class="p-8">
      <div class="d-flex align-items-center justify-content-between mb-7">
        <span class="font-weight-bold text-muted font-size-sm mr-2"
          >Suma punktów</span
        >
        <span class="font-weight-bolder text-primary text-right">{{
          pointsSum()
        }} pkt</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-7">
        <span class="font-weight-bold text-muted font-size-sm mr-2"
          >Dostępne punkty</span
        >
        <span class="font-weight-bolder text-primary text-right">{{
          points
        }} pkt</span>
      </div>
      <div class="text-right" v-if="pointsSum() > 0">
          <b-button
            variant="primary"
            :disabled="canPlaceOrder()"
            @click="checkout"
          >
            Złóż Zamówienie
          </b-button>
      </div>
    </div>
    <!--end::Summary-->
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Commons from "@/core/services/commons.service";
import { ADD_TO_CART, DELETE_FROM_CART, DESTROY_CART } from "@/core/services/store/cart.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "KTDropdownMyCart",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    ...mapGetters(["getCart"])
  },
  data() {
    return {
      points: 0
    };
  },
  mounted() {
    this.refreshPoints();
  },
  methods: {
    refreshPoints() {
      ApiService.get("/overal/points").then(result => {
        this.points = result.data.gainedPoints;
      });
    },
    getImageUrl: Commons.getImageUrl,
    getFirstImageUrl(images) {
      if (images && images.length > 0) {
        return this.getImageUrl(images[0]);
      }
    },
    canPlaceOrder() {
      return this.points - this.pointsSum() < 0;
    },
    pointsSum() {
      return this.getCart
        .map(p => p.quantity * p.prize.erpPrize.cost)
        .reduce((l, r) => l + r, 0);
    },
    decAmount(prize) {
      this.$store.dispatch(ADD_TO_CART, { prize, quantity: -1 });
    },
    incAmount(prize) {
      this.$store.dispatch(ADD_TO_CART, { prize, quantity: 1 });
    },
    removePrize(prize) {
      this.$store.dispatch(DELETE_FROM_CART, { prize });
    },
    clearCart() {
      this.$store.dispatch(DESTROY_CART);
    },
    checkout() {
      const path = '/checkout';
      // avoid NavigationDuplicated
      if (this.$route.path !== path) this.$router.push(path)
    }
  }
};
</script>