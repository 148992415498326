<template>
  <span>
    <ul class="menu-nav" v-if="role === 'ROLE_USER'">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Pulpit </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/transaction-history"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Historia Transakcji </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/prizes-catalog"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Katalog Nagród </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/order-history"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Historia Zamówień </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/punctation"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Punktacja </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/files"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Pliki do Pobrania </span>
          </a>
        </li>
      </router-link>

      <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="menu-text"> Skrzynka Kontaktowa </span>
        </a>
        <div class="menu-submenu menu-submenu-classic">
          <ul class="menu-subnav">
            <router-link
              to="/claims"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-list-2"></i>
                  <span class="menu-text"> Moje Reklamacje </span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/transfer"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-folder-2"></i>
                  <span class="menu-text"> Cesje </span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="menu-nav" v-if="role === 'ROLE_ADMIN'">
      <router-link
        to="/order-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Zamówienia </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/prizes-catalog-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Katalog Nagród </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/promotions"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Promocje </span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/claims-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Reklamacje </span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/files"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Pliki do pobrania </span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/punctation"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Punktacja </span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Uczestnicy </span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/transfer-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text"> Cesje </span>
          </a>
        </li>
      </router-link>
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{
          'menu-item-active':
            hasActiveChildren('/auth-history') ||
            hasActiveChildren('/gained-points-group'),
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="menu-text"> Raporty </span>
        </a>
        <div class="menu-submenu menu-submenu-classic">
          <ul class="menu-subnav">
            <router-link
              to="/auth-history"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-list-2"></i>
                  <span class="menu-text"> Historia sesji </span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/gained-points-group"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-folder-2"></i>
                  <span class="menu-text"> Punkty zdobyte per grupa </span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </ul>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KTMenu",
  computed: mapState({
    role: (state) =>
      state.auth.user ? state.auth.user.role : "ROLE_ANONYMOUS",
  }),
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
